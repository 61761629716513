const _window = $(window);

let slideshowSwiper = new Swiper('.slideshow .swiper', {
	slidesPerView: 'auto',
	spaceBetween: 10,
	slidesOffsetAfter: 10,
	navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}
	/*pagination: {
		el: '.slideshow .swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return '<span class="swiper-pagination-current">' + current + '</span> <span class="swiper-pagination-separator"></span> <span class="swiper-pagination-total">' + total + '</span>';
		}
	}*/
});

let gallerySlideshowSwiper = new Swiper('.gallery-display-as-slideshow .swiper', {
	slidesPerView: 'auto',
	speed:         500,
	autoHeight: true,
	/*pagination:     {
		el: ".swiper-pagination",
	},
	loop:          true,
	autoplay:      {
		delay:                3000,
		stopOnLastSlide:      false
	},*/
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	}
});

let slideshowCalendariSwiper = new Swiper('.slideshow-calendari .swiper', {
	slidesPerView: 1,
	spaceBetween: 0,
	centeredSlides: false,
	loop:          true,
	/*navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},*/
	/*pagination: {
		el: '.slideshow .swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return '<span class="swiper-pagination-current">' + current + '</span> <span class="swiper-pagination-separator"></span> <span class="swiper-pagination-total">' + total + '</span>';
		}
	}*/
});

let slideshowCantieriSwiper = new Swiper('.slideshow-cantieri .swiper', {
	slidesPerView: 5,
	spaceBetween: 10,
	centeredSlides: false,
	direction: 'vertical',
	loop:          document.querySelectorAll('.slideshow-cantieri .swiper-slide').length > 5,
	autoplay:      {
		delay:                4000,
		disableOnInteraction: false,
		stopOnLastSlide:      false
	},
	/*navigation:    {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},*/
	/*pagination: {
		el: '.slideshow .swiper-pagination',
		type: 'custom',
		renderCustom: function (swiper, current, total) {
			return '<span class="swiper-pagination-current">' + current + '</span> <span class="swiper-pagination-separator"></span> <span class="swiper-pagination-total">' + total + '</span>';
		}
	}*/
});

/*let slideshowNewsSwiper = new Swiper('.slideshow-news .swiper', {
	slidesPerView: 6,
	spaceBetween: 10,
	centeredSlides: false,
	loop:          true,
	autoplay:      {
		delay:                3000,
		disableOnInteraction: false,
		stopOnLastSlide:      false
	},
});*/
