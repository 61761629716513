import {inputToToggle} from "./custom-checkbox-radio";

const _window = $(window);
const body = $('body');
import {goToByScroll} from './go-to-by-scroll';
import {utils} from "./utils";

let videoHeroHome = $('.video-home');


if (videoHeroHome.length > 0)
{
	let videoHomeAlreadyOpened = sessionStorage.getItem("videoHomeAlreadyOpened");

	if (videoHomeAlreadyOpened === '1' || document.body.classList.contains('logged-in'))
	{
		$('.video-home-container').hide();
	}
	else if (matchMedia('(min-width: 992px)').matches)
	{
		$('.video-home-container').fadeIn();

		videoHeroHome.attr('preload', 'metadata');
		videoHeroHome.attr('autoplay', '1');
	}

	videoHeroHome[0].addEventListener('ended', function () {
		$('.video-home-container').fadeOut();
		sessionStorage.setItem("videoHomeAlreadyOpened", '1');
	}, false);
}

$('.hero-home__btn').on('click', function () {
	goToByScroll($('#sezione-1'));
})


/**
 * Custom checkboxes in WP Members forms
 */
inputToToggle('form[id*="wpmem"] input[type=checkbox], form[id*="wpmem"] input[type=radio]');

$('form[id*="wpmem"] input[type=checkbox], form[id*="wpmem"] input[type=radio]').each(function () {
	let id = $(this).attr('id');

	$(this).next('.toggle-control').wrap('<label for="' + id + '"></label>');
});


document.querySelectorAll('.sidebar__toggle').forEach(el =>

	el.addEventListener('click', function (e) {
		e.preventDefault();

		let toggle = e.target;
		let transitionDuration = 500;

		if (toggle !== this && this.contains(toggle))
		{
			toggle = toggle.closest('.sidebar__toggle');
		}

		if (toggle.classList.contains('animating'))
		{
			return;
		}
		else
		{
			toggle.classList.add('animating');
		}

		let content = toggle.nextElementSibling;

		toggle.classList.toggle('active');

		utils.slideToggle(content, transitionDuration);

		setTimeout(() => {
			toggle.classList.remove('animating');
		}, transitionDuration);

	})
);

document.querySelectorAll('.sidebar a').forEach(el =>

	el.addEventListener('click', function (e) {

		if (matchMedia('(max-width: 991.98px)').matches)
		{
			e.preventDefault();

			let sidebar = this.closest('.sidebar');
			let toggle = sidebar.querySelectorAll(":scope .sidebar__toggle")[0];
			let transitionDuration = 500;
			let content = toggle.nextElementSibling;

			utils.slideUp(content, transitionDuration);

			setTimeout(() => {
				toggle.classList.remove('animating');
			}, transitionDuration);
		}

	})
);


if (body.hasClass('single-info-immobile'))
{
	body.off('click.smoothscroll');

	$('a[href*="#"]').on('click', function (e) {
		e.preventDefault();

		let target = this.hash,
			$target = $(target);

		if ($target.length > 0)
		{
			$('#index').hide();
			$('#immobile-info-content').show();
			$target.show();

			$('.back').off().on('click', function () {

				$('#index').show();
				$('#immobile-info-content').hide();
				$target.hide();
			});
		}

		return false;
	});
}

/**
 * Reload Eventi page every 60 minutes
 */
if (body.hasClass('page-template-template-eventi'))
{
	window.setTimeout(function () {
		window.location.reload();
	}, 1000 * 60 * 60);
}


/**
 * ON LOAD
 */

_window.on('load', function (event) {

});



/**
 * ON RESIZE
 */

window.addEventListener('resize', function (event) {
	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			const event = new CustomEvent('resizeEnd');
			window.dispatchEvent(event);
		}, 200);
	}
});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
_window.on('resizeEnd', function () {

});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/