//================================= SMOOTH SCROLL

import {goToByScroll} from './go-to-by-scroll';

const _window = $(window);
const body = $('body');

body.on('click.smoothscroll', 'a[href*="#"]', function (e) {
	let target = this.hash,
		$target = $(target);

	if ($target.length > 0)
	{
		e.preventDefault();

		let offset = $(this).data('offset');
		if (typeof offset === 'undefined')
		{
			offset = -$('#header').outerHeight();

			/*if (body.hasClass('single-info-immobile') && matchMedia('(max-width: 991.98px)').matches)
			{
				if (_window.scrollTop() - $target.offset().top > 0)
				{
					// if scrolling up, set the offset as the height of .sidebar-container plus the height of the header plus 30px
					offset -= $('.sidebar-container').outerHeight() + 30;
				}
				else
				{
					// if scrolling down, set the offset as the height of .sidebar-container plus 30px
					offset = -$('.sidebar-container').outerHeight() - 30;
				}
			}*/
		}

		body.removeClass('menu-open');
		$('#menu-toggle').removeClass('tcon-transform');

		goToByScroll($target, offset);

		return false;
	}
});


_window.on('load', function (event) {

	// smooth scroll to anchor on load
	if (location.hash)
	{
		let offset = -$('#header').outerHeight();
		setTimeout(function () {
			goToByScroll($(location.hash), offset);
		}, 200);
	}

});